.privacy-main {
  position: relative;
}

.privacy-main .privacy-top {
  background: #002343;
  color: #ffffff;
  text-align: center;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.533333rem;
}

.privacy-main .privacy-top .title {
  font-size: 0.746667rem;
  font-weight: 600;
  margin-bottom: 0.266667rem;
}

.privacy-main .privacy {
  margin: 0 0.533333rem;
}

.privacy-main .privacy h3 {
  margin-bottom: 0.533333rem;
}

.privacy-main .privacy h4 {
  margin-bottom: 0.4rem;
}

.privacy-main .privacy p {
  margin-bottom: 0.266667rem;
  line-height: 0.746667rem;
}

.privacy-main .privacy .dot {
  padding-left: 0.266667rem;
  display: flex;
  position: relative;
}

.privacy-main .privacy .dot::before {
  content: ' ';
  display: block;
  width: 0.133333rem;
  height: 0.133333rem;
  min-width: 0.133333rem;
  min-height: 0.133333rem;
  max-width: 0.133333rem;
  max-height: 0.133333rem;
  border-radius: 50%;
  background: #333333;
  position: relative;
  top: 0.266667rem;
  right: 0.133333rem;
}